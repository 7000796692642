// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v5.28.3
// source: go.chromium.org/luci/bisection/proto/v1/bugs.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "luci.bisection.v1";

/** Information about a bug associated with a failure. */
export interface BugInfo {
  readonly monorailBugInfo?: MonorailBugInfo | undefined;
  readonly buganizerBugInfo?: BuganizerBugInfo | undefined;
}

export interface MonorailBugInfo {
  /** The project of the bug, e.g. "chromium". */
  readonly project: string;
  /** Monorail bug ID. */
  readonly bugId: number;
}

export interface BuganizerBugInfo {
  /** Buganizer bug ID. */
  readonly bugId: string;
}

function createBaseBugInfo(): BugInfo {
  return { monorailBugInfo: undefined, buganizerBugInfo: undefined };
}

export const BugInfo: MessageFns<BugInfo> = {
  encode(message: BugInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.monorailBugInfo !== undefined) {
      MonorailBugInfo.encode(message.monorailBugInfo, writer.uint32(10).fork()).join();
    }
    if (message.buganizerBugInfo !== undefined) {
      BuganizerBugInfo.encode(message.buganizerBugInfo, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BugInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBugInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.monorailBugInfo = MonorailBugInfo.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.buganizerBugInfo = BuganizerBugInfo.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BugInfo {
    return {
      monorailBugInfo: isSet(object.monorailBugInfo) ? MonorailBugInfo.fromJSON(object.monorailBugInfo) : undefined,
      buganizerBugInfo: isSet(object.buganizerBugInfo) ? BuganizerBugInfo.fromJSON(object.buganizerBugInfo) : undefined,
    };
  },

  toJSON(message: BugInfo): unknown {
    const obj: any = {};
    if (message.monorailBugInfo !== undefined) {
      obj.monorailBugInfo = MonorailBugInfo.toJSON(message.monorailBugInfo);
    }
    if (message.buganizerBugInfo !== undefined) {
      obj.buganizerBugInfo = BuganizerBugInfo.toJSON(message.buganizerBugInfo);
    }
    return obj;
  },

  create(base?: DeepPartial<BugInfo>): BugInfo {
    return BugInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BugInfo>): BugInfo {
    const message = createBaseBugInfo() as any;
    message.monorailBugInfo = (object.monorailBugInfo !== undefined && object.monorailBugInfo !== null)
      ? MonorailBugInfo.fromPartial(object.monorailBugInfo)
      : undefined;
    message.buganizerBugInfo = (object.buganizerBugInfo !== undefined && object.buganizerBugInfo !== null)
      ? BuganizerBugInfo.fromPartial(object.buganizerBugInfo)
      : undefined;
    return message;
  },
};

function createBaseMonorailBugInfo(): MonorailBugInfo {
  return { project: "", bugId: 0 };
}

export const MonorailBugInfo: MessageFns<MonorailBugInfo> = {
  encode(message: MonorailBugInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.project !== "") {
      writer.uint32(10).string(message.project);
    }
    if (message.bugId !== 0) {
      writer.uint32(16).int32(message.bugId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MonorailBugInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMonorailBugInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.project = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.bugId = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MonorailBugInfo {
    return {
      project: isSet(object.project) ? globalThis.String(object.project) : "",
      bugId: isSet(object.bugId) ? globalThis.Number(object.bugId) : 0,
    };
  },

  toJSON(message: MonorailBugInfo): unknown {
    const obj: any = {};
    if (message.project !== "") {
      obj.project = message.project;
    }
    if (message.bugId !== 0) {
      obj.bugId = Math.round(message.bugId);
    }
    return obj;
  },

  create(base?: DeepPartial<MonorailBugInfo>): MonorailBugInfo {
    return MonorailBugInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MonorailBugInfo>): MonorailBugInfo {
    const message = createBaseMonorailBugInfo() as any;
    message.project = object.project ?? "";
    message.bugId = object.bugId ?? 0;
    return message;
  },
};

function createBaseBuganizerBugInfo(): BuganizerBugInfo {
  return { bugId: "0" };
}

export const BuganizerBugInfo: MessageFns<BuganizerBugInfo> = {
  encode(message: BuganizerBugInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bugId !== "0") {
      writer.uint32(8).int64(message.bugId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BuganizerBugInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBuganizerBugInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.bugId = reader.int64().toString();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BuganizerBugInfo {
    return { bugId: isSet(object.bugId) ? globalThis.String(object.bugId) : "0" };
  },

  toJSON(message: BuganizerBugInfo): unknown {
    const obj: any = {};
    if (message.bugId !== "0") {
      obj.bugId = message.bugId;
    }
    return obj;
  },

  create(base?: DeepPartial<BuganizerBugInfo>): BuganizerBugInfo {
    return BuganizerBugInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BuganizerBugInfo>): BuganizerBugInfo {
    const message = createBaseBuganizerBugInfo() as any;
    message.bugId = object.bugId ?? "0";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
